import React from 'react'

import { DescriptionList, DescriptionListItem } from '@te-digi/styleguide'

const DescriptionListGapYStripedExample = () => (
  <DescriptionList
    noMargin
    gapY="xxxl"
    variant="striped"
  >
    <DescriptionListItem
      label="Lorem ipsum dolor sit amet consectetuer adipiscing elit"
      labelWidth={{ xs: 12, sm: 6 }}
    >
      Sed arcu lectus
    </DescriptionListItem>
    <DescriptionListItem
      label="Sed posuere interdum semn"
      labelWidth={{ xs: 12, sm: 6 }}
    >
      Auctor vitae
    </DescriptionListItem>
    <DescriptionListItem
      label="Quisque ligula eros ullamcorper quis"
      labelWidth={{ xs: 12, sm: 6 }}
    >
      Consectetuer et venenatis eget velit sed augue orci lacinia eu tincidunt
      et eleifend nec lacus
    </DescriptionListItem>
    <DescriptionListItem
      label="Lacinia quis facilisis sed sapien"
      labelWidth={{ xs: 12, sm: 6 }}
    >
      Donec ultricies nisl ut felis
    </DescriptionListItem>
    <DescriptionListItem
      label="Mauris varius diam vitae arcu"
      labelWidth={{ xs: 12, sm: 6 }}
    >
      Suspendisse potenti lorem ipsum ligula ut hendrerit mollis, ipsum erat
      vehicula risus
    </DescriptionListItem>
  </DescriptionList>
)

export { DescriptionListGapYStripedExample }
